.addLineItemModal {
  max-width: 450px;
}

.addLineItemModal :global(.ant-modal-header) {
  border-radius: 0;
}

.empty {
  height: 100px;
}

.fetchLoader {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs);
}
