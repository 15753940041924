.modalDiv {
  border-radius: 8px;
}

.modalDiv :global(.ant-modal-header) {
  border: 0;
  font: var(--copy-base-medium);
}

.modalDiv :global(.ant-modal-footer) {
  border: 0;
}

.modalDiv :global(.ant-modal-footer .ant-btn) {
  color: var(--color-blue-500);
  border: 0;
}

.modalDiv :global(.ant-modal-footer .ant-btn-primary) {
  color: #ffffff;
}
