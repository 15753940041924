.datePicker {
    height: 40px;
}

.invoiceSummaryDrawer :global(.ant-drawer-body) {
    padding: 0 var(--spacing-6);
}

.invoicesActionButtonsContainer {
    margin-bottom: 16px;
    display: flex;
    justify-content: end;
    gap: 16px;
}

.invoicesTable table {
    background: var(--gray-1);
}

.invoicesTable :global(.ant-table-content) {
    overflow-x: auto;
}

.paginatedList {
    margin-top: var(--spacing-m);
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 64px;
}

.loaderText {
    font: var(--copy-s-regular);
    text-align: center;
    margin-top: var(--spacing-4);
}

.invoiceCompanyStats {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-8) 0 var(--spacing-6);
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.invoiceSalesStats {
    display: flex;
    flex-direction: column;
}

.pocInfo {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: var(--spacing-4);
}

.justifySelfEnd {
    justify-self: flex-end;
}

.invoiceCompanyName {
    font: var(--copy-s-medium)
}

.invoiceTable table {
    background: var(--gray-1);
}

.invoiceTable :global(.ant-table-content) {
    overflow-x: auto;
}


.invoiceTable :global(.ant-table-tbody > tr.ant-table-row:hover > td) {
    background-color: var(--blue-11);
    cursor: pointer;
}

.invoiceSummary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-5) 0 var(--spacing-6);
    position: sticky;
    bottom: 0;
    background-color: white;
}

.addLineItemButton {
    display: flex;
    align-items: center;
    gap: 4px;
}

.invoiceTotalStats {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    gap: var(--spacing-0);
}

.buttonWrap {
    display: flex;
    gap: var(--spacing-3);
}

.invoiceStatValue {
    min-width: 120px;
    text-align: end;
}