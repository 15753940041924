/* common styles */
.requiredText {
  color: var(--red-5);
  font-weight: 600;
}

.fieldContainer {
  margin-bottom: var(--spacing-s);
  position: relative;
}

.borderRed {
  border: 1px solid var(--red-6);
}

.labelText {
  color: var(--gray-10);
  display: block;
  font-size: 0.875rem;
  margin-bottom: var(--spacing-s);
}

.labelTextMarginDisabled {
  margin: 0 !important;
}

/* select field */
.selectField :global(.ant-select-multiple .ant-select-selection-item) {
  font-size: 0.875rem;
}

.selectField :global(.ant-select-disabled .ant-select-selector > span) {
  margin-right: var(--spacing-m);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* selection field */
.selectionField {
  position: relative;
}

.selectionField input {
  visibility: hidden;
  display: none;
}

.selectionField > label {
  border-radius: 2px;
  border: solid 1px var(--gray-5);
  color: var(--gray-10);
  cursor: pointer;
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-m) 0 var(--spacing-xxl-6);
}

.selectionField > label :global(.anticon) {
  position: absolute;
  left: 1rem;
}

.selectionField .labelValue {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.selectionField > .selected {
  border-color: var(--blue-6);
  transition: border-color 500ms;
}

.selectionField > label svg {
  fill: var(--gray-7);
}

.selectionField > .selected svg {
  fill: var(--blue-6);
}

/* social */
.AddLinkInputField {
  margin-top: var(--spacing-xxl);
}

.socialPrefixIcon {
  color: var(--blue-6);
}

.socialSuffixIcon {
  color: var(--gray-8);
  font-size: 14px;
}

.socialField:not(:last-of-type) {
  margin-bottom: var(--spacing-xl);
}

.socialField > :global(.ant-input-prefix) {
  margin-right: var(--spacing-s);
}

/* Block Selection*/

.visiblityHidden {
  visibility: hidden;
}

.verticalBlock {
  border: 1px solid var(--gray-5);
  padding: var(--spacing-m);
  color: var(--gray-7);
  width: 100%;
  min-width: 110px;
  cursor: pointer;
  text-align: center;
  transition: all ease-in-out 0.2s;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  max-height: 110px;
}

.activeVerticalBlock {
  border: 1px solid var(--blue-6);
  color: var(--gray-10);
  padding: var(--spacing-m);
}

.disabledVerticalBlock {
  cursor: not-allowed;
}

.verticalBlogTitle {
  margin-top: var(--spacing-s);
  font-size: 0.62rem;
}

/* Slider*/
.rangeTitle {
  margin-top: var(--spacing-xl);
}

/* Switch */
.metaSwitch {
  display: flex;
  max-width: 100px;
  justify-content: space-between;
}

/* tags */
.tagsFieldSelect {
  width: 100px;
}

/* Tag Select */

.tagBlocksSelection span {
  display: inline-block;
  margin-bottom: var(--spacing-m);
  cursor: pointer;
}

.blockSelect__button {
  padding: var(--spacing-xl) 0;
}

.visibilityHidden {
  visibility: hidden;
}

.tagBlock {
  color: var(--gray-7);
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}

.iconBlock {
  filter: grayscale(1);
  opacity: 0.7;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.iconBlock:hover {
  opacity: 1;
  filter: grayscale(0);
}

.iconTitle {
  width: 150px;
  color: var(--blue-6);
  font-size: 0.825rem;
  margin-top: var(--spacing-s);
  white-space: wrap;
  text-overflow: clip;
}

.activeTag {
  filter: grayscale(0);
  opacity: 1;
  color: var(--blue-6);
}

.error {
  color: var(--gray-7);
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  margin-top: var(--spacing-xs);
}

.errorIcon {
  color: var(--red-6);
  margin-right: var(--spacing-xxs);
}

.helperText {
  color: var(--gray-7);
  font-size: 0.75rem;
  margin-top: var(--spacing-s);
}

/* list field */
.list {
  list-style: none;
  padding: 0;
  margin: var(--spacing-m) 0 0 0;
}

.list li {
  color: var(--gray-8);
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-xxs);
}

.list svg {
  color: var(--gray-8);
}

/* social */

.addLinkBtn {
  display: flex;
  align-items: center;
}

/* Block Selection*/

.blocksSelection {
  display: grid;
  grid-template-columns: repeat(4, 7.25rem);
  grid-gap: var(--spacing-xl);
  position: relative;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.active {
  border: 1px solid var(--blue-6);
  color: var(--blue-6);
}

.verticalBlock img {
  width: 2.75rem;
  height: 2.75rem;
  position: relative;
  top: var(--spacing-s);
}

.verticalBlockCheck,
.verticalBlockLock {
  position: absolute;
  top: var(--spacing-xxs);
  right: var(--spacing-xxs);
}

.blockTag {
  position: absolute;
  top: 0px;
  right: -8px;
  font-size: 0.5rem;
  padding: var(--spacing-xxs);
  color: var(--gray-1);
  background-color: var(--cyan-6);
}

.blockTag::after {
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--cyan-6);
  position: absolute;
  right: 0;
  bottom: -8px;
  z-index: -1;
}

.verticalBlockLock {
  filter: grayscale(1);
}

.verticalBlogTitle {
  position: relative;
  top: var(--spacing-s);
  font-size: 0.625rem;
  margin-top: var(--spacing-xs);
  text-overflow: clip;
  overflow: hidden;
}

.active .verticalBlogTitle {
  font-weight: 500;
}

/* Slider*/

.switchBtn {
  margin-top: var(--spacing-xxs);
}

/* Tag Select */
.iconBlocksSelection {
  display: flex;
  justify-content: center;
  gap: var(--spacing-xxl-2);
}

.activeBlock {
  border: 1px solid;
  padding: 5px;
}

/* Dimensions field*/

.dimensionFieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-l);
}

.dimensionsAction {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-m);
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
}

.deleteDimensionIcon {
  margin-left: var(--spacing-m);
}

.commentBoxContainer {
  padding: 0;
  border: none;
  background: transparent;
}

.colorBlock,
.hoverEffectBlock {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray-5);
  border-radius: 2px;
  color: var(--gray-7);
  text-align: center;
  transition: all ease-in-out 0.2s;
  position: relative;
  width: 100%;
}

.colorBlock > div {
  width: 100%;
}

.colorBlock img {
  width: 100%;
  height: 100%;
}

.colorBlock input {
  position: absolute;
  width: 100%;
  height: 100%;
}

.colorBlockTitle {
  text-align: center;
  top: var(--spacing-s);
  font-size: 0.625rem;
  margin-top: var(--spacing-xs);
  text-overflow: clip;
  overflow: hidden;
  margin-bottom: var(--spacing-l);
}

.colourBlockActive {
  border: 1px solid var(--blue-6);
  color: var(--blue-6);
}

.hoverEffectBlock img {
  opacity: 1;
  display: block;
}

.hoverEffectBlock img:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: 1;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.box {
  position: relative;
}

.box:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
}

.box:hover:after {
  opacity: 1;
}

.box:hover .hoverTitleBlock {
  opacity: 1;
}

.hoverTitleBlock {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  opacity: 0;
  z-index: 1;
  height: 100%;
}

.ColourBlocksSelection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--spacing-s);
  position: relative;
}

.previewIcon {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: var(--spacing-xs);
}

.previewModal {
  display: flex;
  align-items: center;
}

.infoIcon {
  margin-left: var(--spacing-m);
  color: var(--gray-7);
}

.imageSrcContainer {
  margin-top: var(--spacing-s);
}

.colorBoxContainer {
  padding: var(--spacing-m);
  width: 40px;
  height: 40px;
  border: 1px solid var(--gray-7);
}

.specificColorLabel {
  color: var(--blue-6);
  border-bottom: 1px solid var(--blue-6);
  cursor: pointer;
  margin-top: var(--spacing-l);
}

.fieldBlockContainer {
  margin-top: var(--spacing-xxl-2);
}

.colorPickerInputField {
  width: 50px;
  padding: 0px;
  border: none;
}

.previewImage {
  width: 100%;
}

.customColorContainer {
  margin-top: var(--spacing-l);
}

.textGroupSelect {
  width: 50% !important;
}

.antInputGroup {
  display: flex;
}

.labelFlex {
  display: flex;
  width: 100%;
}

.labelFlex > * {
  width: 50%;
}

.searchInput {
  margin-bottom: var(--spacing-xxl-2);
}

.inputLabelV2 {
  color: var(--gray-80);
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: var(--spacing-xs);
  display: block;
  display: flex;
}

.inputAreaV2 {
  color: var(--gray-80);
  border: 1px solid var(--gray-40);
  border-radius: 0.5rem !important;
  font-size: 0.88rem;
  padding: 0.7rem var(--spacing-m);
  height: 2.5rem;
  margin-bottom: var(--spacing-xs);
}

.delete {
  height: 1rem;
  width: 1rem;
  color: var(--gray-90);
  cursor: pointer;
  transition: all 0.2s;
}

.delete:hover {
  color: var(--orange-50);
}

.addLink {
  padding: 0;
  color: var(--font-blue);
}

.error {
  color: var(--gray-7);
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  margin-top: var(--spacing-xs);
}

.errorIcon {
  color: var(--red-6);
  margin-right: var(--spacing-xxs);
}

.tooltipPos {
  position: relative;
  top: 2px;
  margin-left: var(--spacing-xs);
}

.requiredText {
  color: var(--red-5);
  font-weight: 600;
}

.inputAreaV2 {
  color: var(--gray-80);
  border: 1px solid var(--gray-40);
  border-radius: 0.5rem !important;
  font-size: 0.88rem;
  padding: 0.7rem var(--spacing-m);
  height: 2.5rem;
}

.borderRed {
  border: 1px solid var(--orange-50);
  box-shadow: 0px 0px 0px 4px rgb(255 102 102 / 5%);
}

.searchInput :global(.ant-select-selector):hover {
  border: 1px solid var(--blue-40) !important;
  box-shadow: 0px 0px 0px 4px rgba(117, 102, 255, 0.05) !important;
}

.searchInput :global(.ant-select-focused) > :global(.ant-select-selector) {
  border: 1px solid var(--blue-40) !important;
  box-shadow: 0px 0px 0px 4px rgba(117, 102, 255, 0.05) !important;
}

.error {
  color: var(--magenta-60);
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  margin-top: var(--spacing-xs);
}

.errorIcon {
  color: var(--red-6);
  margin-right: var(--spacing-xxs);
}

.templateNameIcon {
  display: flex;
  flex-direction: row;
}

.templatePopover :global(.ant-popover-inner-content) {
  padding: 0;
}

:global(.ant-collapse) {
  padding-bottom: var(--spacing-3);
}

:global(.ant-collapse-extra) {
  margin-right: var(--spacing-3);
}

:global(.ant-collapse-header) {
  display: flex;
  flex-flow: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.plusIcon {
  padding-top: var(--spacing-0);
}

.templateIcon {
  padding: var(--spacing-s);
  text-align: center;
}

.expandedTemplateIcon {
  padding: var(--spacing-s);
}

.expandedTemplateIcon:hover {
  color: var(--font-blue);
}

.templateImageInList {
  stroke: var(--color-gray-700);
  transform: scale(1.15);
  margin-top: 6px;
}

.templateListWrap {
  margin-top: var(--spacing-3);
  position: relative;
}

.templateListOverlayWrap {
  position: absolute;
  box-shadow: 0px 2px 8px rgba(83, 83, 83, 0.1);
  border-radius: 0.5rem;
  padding: var(--spacing-3) 0 0 0;
  width: 100%;
  background: var(--gray-1);
  z-index: 1;
}

.inputAreaV2 :global(.ant-input-suffix) {
  cursor: pointer;
}

.csBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.csForeground {
  position: relative;
  z-index: 200;
}

.templateSearchIcon {
  color: var(--gray-40);
  transform: scale(0.85);
}

.customSelectsearchWrapper {
  margin-bottom: var(--spacing-8);
}
